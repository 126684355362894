import React from 'react';
import { ProductDisplay } from './ProductDisplay';

interface SubscriptionPlanProps {
  planName: string;
  price: string;
  lookupKey: string;
  description: string;
}

export const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  planName,
  price,
  lookupKey,
  description,
}) => {
  return (
    <div className="subscription-plan">
      <ProductDisplay planName={planName} description={description} price={price} lookupKey={lookupKey} />
    </div>
  );
};

export default SubscriptionPlan;