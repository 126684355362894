import '../../styles/components/loading/loading-dots-text.css';
interface LoadingDotTextProps {
	text: string;
}

export const LoadingDotText: React.FC<LoadingDotTextProps> = ({ text }) => {
	return (
		<div className="loading-dots-wrapper">
			<h1 className="h1-loading">
				{text}
				<span className="dot-one"> .</span>
				<span className="dot-two"> .</span>
				<span className="dot-three"> .</span>
			</h1>
		</div>
	);
};

export default LoadingDotText;
