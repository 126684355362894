import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createPortalSessions } from '../../services/stripe.service';
import { Logo } from './Logo';

interface SuccessDisplayProps {
	sessionId: string;
}

export const SuccessDisplay: React.FC<SuccessDisplayProps> = ({
	sessionId,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const { getAccessTokenSilently } = useAuth0();

	const handleSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault();
		const accessToken = await getAccessTokenSilently();
		setIsLoading(true);
		setError('');

		const sessionData = { session_id: sessionId };
		const { data, error } = await createPortalSessions(
			accessToken,
			sessionData
		);

		if (data.url) {
			// Open a new window with the URL from the response body
			window.open(data.url, '_blank');
			setIsLoading(false);
		}

		if (error) {
			setError(error.message);
			setIsLoading(false);
		}
	};

	return (
		<section className="success-display">
			<div className="product">
				<Logo />
				<div className="description">
					<h3>Subscription to starter plan successful!</h3>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				<input
					type="hidden"
					id="session-id"
					name="session_id"
					value={sessionId}
				/>
				<button id="checkout-and-portal-button" type="submit">
					Manage your billing information
				</button>
			</form>
			{isLoading ? (
				<p className="loading-message">Loading...</p>
			) : error ? (
				<p className="error-message">Error: {error}</p>
			) : null}
		</section>
	);
};

export default SuccessDisplay;
