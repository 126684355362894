import { PageFooterHyperlink } from './PagerFooterHyperlink';

export const PageFooter = () => {
	const currentYear = new Date().getFullYear();
	return (
		<footer className="page-footer">
			<div className="page-footer-grid">
				<div className="page-footer-grid__brand">
					<div className="page-footer-brand">
						<PageFooterHyperlink path="https://auth0.com/"></PageFooterHyperlink>
					</div>
					<div>JDKonsult &copy; {currentYear}</div>
				</div>
			</div>
		</footer>
	);
};
