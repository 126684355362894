import React from 'react';
import { NavLink } from 'react-router-dom';

interface StockNavBarTabProps {
	path: string;
	label: string;
}

export const StockNavBarTab: React.FC<StockNavBarTabProps> = ({
	path,
	label,
}) => {
	return (
		<NavLink
			to={path}
			className={({ isActive }) =>
				isActive ? 'nav-bar__tab nav-bar__tab--active' : 'nav-bar__tab'
			}
		>
			{label}
		</NavLink>
	);
};
