import React from 'react';

interface MarketAnalysisProps {
	ticker: string;
}

export const MarketAnalysis: React.FC<MarketAnalysisProps> = ({ ticker }) => {
	return (
		<div className="auth0-features">
			<div className="ai-analysis-container">
				{/* <DailyClosePrice ticker={ticker} /> */}
			</div>
		</div>
	);
};
