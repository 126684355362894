import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getAiAnalysis } from '../../../services/stock.service';
import { AIAnalysis, YearlyScore } from 'src/models/stock-types';
import { LoadingDotText } from '../../Loading/LoadingDotsText';

interface AiAnalysisProps {
	ticker: string;
}

export const AiAnalysis: React.FC<AiAnalysisProps> = ({ ticker }) => {
	const [aiAnalysisString, setAiAnalysisString] = useState<AIAnalysis>();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const [yearRange, setYearRange] = useState(3);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 600);
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const { getAccessTokenSilently } = useAuth0();
	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			const accessToken = await getAccessTokenSilently();
			setIsLoading(true);
			setAiAnalysisString(undefined);
			setError('');

			const { data, error } = await getAiAnalysis(
				accessToken,
				ticker,
				yearRange
			);
			if (!isMounted) {
				return;
			}

			if (data) {
				setAiAnalysisString(data);
				setIsLoading(false);
			}

			if (error) {
				setError(error.message);
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, [getAccessTokenSilently, ticker]);

	const renderAnalysis = (analysis: AIAnalysis) => {
		if (!analysis) return null;

		const sentenceRegex = /(?<!\d)\.\s+|\.$/;
		const mobileRegex = /(?<!\d)\.\s+|,|\.$/; // More granular splitting for mobile

		let financialLines = [];
		let recommendationLines = [];

		const regexToUse = isMobile ? mobileRegex : sentenceRegex;

		financialLines = analysis.financialSummary
			.split(regexToUse)
			.map((line) => line.trim())
			.filter((line) => line.length > 0);

		recommendationLines = analysis.gptRecommendation
			.split(regexToUse)
			.map((line) => line.trim())
			.filter((line) => line.length > 0);

		return (
			<div>
				<h3 className="yearly-scores-title">Financial Summary</h3>
				<div className="analysis-container">
					{financialLines.map((line, index) => (
						<div
							key={index}
							className={`analysis-line ${index === 0 ? 'active' : ''}`}
						>
							{line}.
						</div>
					))}
				</div>
				<h3 className="yearly-scores-title">AI Recommends</h3>
				<div className="analysis-container">
					{recommendationLines.map((line, index) => (
						<div
							key={index}
							className={`analysis-line ${index === 0 ? 'active' : ''}`}
						>
							{line}.
						</div>
					))}
				</div>
			</div>
		);
	};

	return (
		<div className="auth0-features">
			{error ? (
				<p className="error-message">Error: {error}</p>
			) : isLoading ? (
				<div>
					<h3 className="yearly-scores-title">
						<LoadingDotText
							text={`Generating AI analysis based on financial data`}
						/>
					</h3>
				</div>
			) : (
				<div className="ai-analysis-container">
					{error ? (
						<p className="error-message">Error: {error}</p>
					) : isLoading ? (
						<div className="loading-container">
							<LoadingDotText
								text={`Generating AI analysis based on financial data`}
							/>
						</div>
					) : (
						<div className="analysis-content">
							{aiAnalysisString && renderAnalysis(aiAnalysisString)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
