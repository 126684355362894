import React from 'react';
import { Score, YearlyScore } from 'src/models/stock-types';

interface StockDetailProps {
	year: number;
	yearScore: YearlyScore;
	previousYearScore: YearlyScore | null;
}

export const StockDetail: React.FC<StockDetailProps> = ({
	year,
	yearScore,
	previousYearScore,
}) => {
	const capitalize = (str: string) => {
		return str.replace(/_/g, ' ').replace(/\b(\w)/g, (s) => s.toUpperCase());
	};

	const formatPercentage = (value: number) => {
		return `${(value * 100).toFixed(2)}%`;
	};

	const formatMonetary = (value: number) => {
		return value.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		});
	};

	const formatDifference = (
		current: number,
		previous?: number,
		isPercentage?: boolean
	) => {
		if (previous === undefined) {
			return <span className="neutral-difference">0.00</span>;
		}
		const difference = current - previous;
		const formattedDifference = isPercentage
			? formatPercentage(difference)
			: formatMonetary(difference);
		let differenceClass = 'neutral-difference';

		if (difference > 0) {
			differenceClass = 'positive-difference';
		} else if (difference < 0) {
			differenceClass = 'negative-difference';
		}

		return (
			<span className={differenceClass}>
				{difference >= 0 ? `+${formattedDifference}` : `${formattedDifference}`}
			</span>
		);
	};

	return (
		<h3 className="auth0-feature__headline">
			<ul>
				<h1 className="auth0-feature__year">{year}</h1>
				{Object.entries(yearScore.scores).map(([key, value]) => {
					const safeKey = key as keyof Score;
					const previousValue = previousYearScore
						? previousYearScore.scores[safeKey]
						: undefined;

					const isPercentage = key.toLowerCase().includes('margin');
					const formattedValue = isPercentage
						? formatPercentage(value)
						: formatMonetary(value);

					return (
						<li className="auth0-feature__description" key={key}>
							{`${capitalize(key)}: ${formattedValue} `}
							{formatDifference(value, previousValue, isPercentage)}
						</li>
					);
				})}
			</ul>
		</h3>
	);
};
