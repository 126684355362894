import React from 'react';
import { PageLayout } from '../components/PageLayout';
import { Route, Routes, useParams } from 'react-router-dom';
import { StockNavBar } from 'src/components/Navigation/StockNavigation/StockNavBar';
import { StockDetails } from 'src/components/Stock/FinancialData/StockDetails';
import { AiAnalysis } from 'src/components/Stock/AiAnalysis/AiAnalysis';
import { MarketAnalysis } from 'src/components/Stock/Market/MarketAnalysis';
import StockPageHeader from 'src/components/Stock/Market/StockPageHeader';

interface RouteParams {
	stockTicker: string;
}

export const StockPage: React.FC = () => {
	const routeParams = useParams();
	const stockTicker = routeParams.stockTicker;

	return (
		<PageLayout>
			<div className="content-layout">
				{stockTicker && (
					<div>
						<h1 id="page-title" className="content__title">
							<StockPageHeader ticker={stockTicker} />
						</h1>
						<div className="navigation-tabs">
							<StockNavBar stockTicker={stockTicker} />
						</div>
						<Routes>
							<Route
								path={`financialData`}
								element={<StockDetails ticker={stockTicker!} />}
							/>
							<Route
								path={`aiAnalysis`}
								element={<AiAnalysis ticker={stockTicker!} />}
							/>
							<Route
								path={`marketAnalysis`}
								element={<MarketAnalysis ticker={stockTicker!} />}
							/>
						</Routes>
					</div>
				)}
			</div>
		</PageLayout>
	);
};
