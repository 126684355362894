import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
	AiAnalysisApiResponse,
	ApiResponse,
	FinancialSummaryApiResponse,
	StockDataApiResponse,
	StockDetailsApiResponse,
	StockFilingsApiResponse,
} from "../models/api-response";
import { AppError } from "../models/app-error";

async function fetchApiData<T>(options: {
	config: AxiosRequestConfig;
}): Promise<ApiResponse<T>> {
	try {
		const response: AxiosResponse = await axios(options.config);

		return {
			data: response.data.data,
			error: null,
		};
	} catch (error) {
		return handleError(error);
	}
}

async function fetchApiDataFlatJson<T>(options: {
	config: AxiosRequestConfig;
}): Promise<ApiResponse<T>> {
	try {
		const response: AxiosResponse = await axios(options.config);

		return {
			data: response.data,
			error: null,
		};
	} catch (error) {
		return handleError(error);
	}
}

function handleError<T>(error: unknown): ApiResponse<T> {
	let message = "An error occurred while retrieving data.";

	if (axios.isAxiosError(error)) {
		const axiosError = error as AxiosError<AppError>;
		message = axiosError.message;

		if (axiosError.response) {
			message = axiosError.response.statusText || message;
			message = axiosError.response.data?.message || message;
		}
	} else if (error instanceof Error) {
		message = error.message;
	}

	return {
		data: null,
		error: { message },
	};
}

export const fetchStockDetails = async (options: {
	config: AxiosRequestConfig;
}): Promise<ApiResponse<StockDetailsApiResponse>> => {
	const data = fetchApiData<StockDetailsApiResponse>(options);
	return data;
};

export const fetchStockFilings = async (options: {
	config: AxiosRequestConfig;
}): Promise<ApiResponse<StockFilingsApiResponse>> => {
	return fetchApiData<StockFilingsApiResponse>(options);
};

export const fetchStockData = async (options: {
	config: AxiosRequestConfig;
}): Promise<ApiResponse<StockDataApiResponse>> => {
	return fetchApiDataFlatJson<StockDataApiResponse>(options);
};

export const fetchStockSummary = async (options: {
    config: AxiosRequestConfig;
}): Promise<ApiResponse<FinancialSummaryApiResponse>> => {
    return fetchApiDataFlatJson<FinancialSummaryApiResponse>(options);
};

export const fetchAiAnalysis = async (options: {
	config: AxiosRequestConfig;
}): Promise<ApiResponse<AiAnalysisApiResponse>> => {
	return fetchApiData<AiAnalysisApiResponse>(options);
};

export const fetchApiWakeupRequest = async (options: {
	config: AxiosRequestConfig;
}): Promise<any> => {
	return fetchApiData<any>(options);
};
