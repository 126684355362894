import React from 'react';
import { PageLayout } from '../components/PageLayout';
import { StockDetails } from '../components/Stock/FinancialData/StockDetails';
import { useParams } from 'react-router-dom';

export const StockDetailsPage: React.FC = () => {
	const routeParams = useParams();
	const { stockTicker } = routeParams;

	return (
		<PageLayout>
			<div className="content-layout">
				<h1 id="page-title" className="content__title">
					Stock Details for {stockTicker}
				</h1>
				<StockDetails ticker={stockTicker!} />
			</div>
		</PageLayout>
	);
};
