import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Stock } from 'src/models/stock-types';

interface StockContextType {
	stocks: Stock[];
	setStocks: React.Dispatch<React.SetStateAction<Stock[]>>;
}

const StockContext = createContext<StockContextType | undefined>(undefined);

export const StockProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [stocks, setStocks] = useState<Stock[]>([]);

	return (
		<StockContext.Provider value={{ stocks, setStocks }}>
			{children}
		</StockContext.Provider>
	);
};

export const useStockContext = (): StockContextType => {
	const context = useContext(StockContext);
	if (!context) {
		throw new Error('useStockContext must be used within a StockProvider');
	}
	return context;
};
