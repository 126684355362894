import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { MobileNavBarTab } from './MobileNavBarTab';

interface MobileNavBarTabsProps {
	handleClick: () => void;
}

export const MobileNavBarTabs: React.FC<MobileNavBarTabsProps> = ({
	handleClick,
}) => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="mobile-nav-bar__tabs">
			{isAuthenticated && (
				<>
					<MobileNavBarTab
						path="/stockFilings"
						label="Filings"
						handleClick={handleClick}
					/>
					<MobileNavBarTab
						path="/stockDetails"
						label="Stock"
						handleClick={handleClick}
					/>
					<MobileNavBarTab
						path="/profile"
						label="Profile"
						handleClick={handleClick}
					/>
				</>
			)}
		</div>
	);
};
