import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createCheckoutSessions } from '../../services/stripe.service';
import { YearlyScore } from 'src/models/stock-types';
import { Logo } from './Logo';

interface ProductDisplayProps {
	planName: string;
	price: string;
	lookupKey: string;
	description: string;
}

export const ProductDisplay: React.FC<ProductDisplayProps> = ({
	planName,
	price,
	lookupKey,
	description,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const { getAccessTokenSilently } = useAuth0();

	const fetchData = async (event: { preventDefault: () => void }) => {
		event.preventDefault();
		const accessToken = await getAccessTokenSilently();
		setIsLoading(true);
		setError('');

		const sessionData = { lookup_key: lookupKey };
		const { data, error } = await createCheckoutSessions(
			accessToken,
			sessionData
		);

		if (data.url) {
			// Open a new window with the URL from the Location header
			window.open(data.url, '_blank');
			setIsLoading(false);
		}

		if (error) {
			setError(error.message);
			setIsLoading(false);
		}
	};

	return (
		<section className="product-display">
			<div className="product">
				<Logo />
				<div className="description">
					<h3>{planName}</h3>
					<h5>{price} / month</h5>
				</div>
				<div>
					<p className="plan-details">{description}</p>
				</div>
			</div>
			<form onSubmit={fetchData}>
				{/* Add a hidden field with the lookup_key of your Price */}
				<input type="hidden" name="lookup_key" value={lookupKey} />
				<button id="checkout-and-portal-button" type="submit">
					Checkout
				</button>
			</form>
			{isLoading ? (
				<p className="loading-message">Loading...</p>
			) : error ? (
				<p className="error-message">Error: {error}</p>
			) : null}
		</section>
	);
};

export default ProductDisplay;
