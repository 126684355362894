import React from 'react';
import { StockNavBarTabs } from './StockNavBarTabs';

interface StockNavBarProps {
	stockTicker: string | undefined;
}

export const StockNavBar: React.FC<StockNavBarProps> = ({ stockTicker }) => {
	return (
		<div>
			<nav className="nav-bar">
				<StockNavBarTabs stockTicker={stockTicker} />
			</nav>
		</div>
	);
};
