import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../components/PageLayout';

export const StockSelectPage: React.FC = () => {
	const [userSuppliedTicker, setUserSuppliedTicker] = useState('');
	const navigate = useNavigate();

	const handleSearch = () => {
		if (userSuppliedTicker) {
			navigate(`/stockDetails/${userSuppliedTicker}/financialData`);
		}
	};

	const handleFetchTopStocks = () => {
		navigate('/stockDetails/topRankedStocks');
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserSuppliedTicker(e.target.value.toUpperCase());
	};

	return (
		<PageLayout>
			<div className="stock__select__content-layout">
				<div className="divider">Get top ranked stocks</div>
				<button onClick={handleFetchTopStocks}>Go</button>
				<div className="divider">Search for stock</div>
				<input
					type="text"
					value={userSuppliedTicker}
					onChange={handleInputChange}
					placeholder="Enter Stock Ticker"
					onFocus={(e) => (e.target.placeholder = '')}
					onBlur={(e) => (e.target.placeholder = 'Enter Stock Ticker')}
				/>
				<button onClick={handleSearch}>Search</button>
			</div>
		</PageLayout>
	);
};
