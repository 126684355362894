import React, { useEffect, useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { GenericTanStackTable } from '../components/Table/GenericTable';
import { ColumnDef } from '@tanstack/react-table';
import { StockData } from 'src/models/stock-types';
import { getStockData } from 'src/services/stock.service';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingDotText from 'src/components/Loading/LoadingDotsText';
import { useNavigate } from 'react-router-dom';

export const StockTopRankedPage: React.FC = () => {
    const [stockData, setStockData] = useState<StockData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const handleRowClick = (ticker: string) => {
        navigate(`/stockDetails/${ticker}/financialData`);
    };

    useEffect(() => {
        const fetchStockData = async () => {
            const accessToken = await getAccessTokenSilently();
            setIsLoading(true);
            setError('');

            try {
                const response = await getStockData(accessToken);
                console.log(response);
                setStockData(response.data || []);
            } catch (error) {
                setError('Error fetching stock data:' + error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchStockData();
    }, []);

    const columns: ColumnDef<StockData, any>[] = [
        { accessorKey: 'Ticker', header: 'Ticker' },
        { accessorKey: 'CompanyName', header: 'Company Name' },
        {
            accessorKey: 'TrendAdjustedScore',
            header: 'Financial Score',
            cell: (info) => info.getValue().toFixed(0),
        },
        {
            accessorKey: 'FinancialStrengthPercentage',
            header: 'Financial Strength %',
        },
        {
            accessorKey: 'InsiderFilingScore',
            header: 'Filing Score',
            cell: (info) => info.getValue().toFixed(0),
        },
        {
            accessorKey: 'Rating',
            header: 'Rating',
        },
        {
            accessorKey: 'RatingScore',
            header: 'Rating Score',
        },
        {
            accessorKey: 'Recommendation',
            header: 'Recommendation',
        },
        // { accessorKey: 'Industry', header: 'Industry' },
        {
            accessorKey: 'TradeDate',
            header: 'Trade Date',
            cell: (info) => new Date(info.getValue() as string).toLocaleDateString(),
        },
        {
            accessorKey: 'NumberOfInsiderTraders',
            header: 'Insider Traders',
        },
        // { accessorKey: 'TradeType', header: 'Type' },
        {
            accessorKey: 'PurchasePrice',
            header: 'Purchase Price',
            cell: (info) => `$${(info.getValue() as number).toFixed(0)}`,
        },
        { accessorKey: 'Quantity', header: 'Quantity' },
        {
            accessorKey: 'PreviouslyOwned',
            header: 'Previously Owned',
        },
        {
            accessorKey: 'PercentageOwned',
            header: 'Percentage Owned',
            cell: (info) => `${info.getValue()}%`,
        },
        {
            accessorKey: 'PurchaseTotal',
            header: 'Purchase Total',
            cell: (info) => `$${(info.getValue() as number).toLocaleString()}`,
        },
    ];

    return (
        <PageLayout>
            <div className="top_ranked_content-layout">
                {/* <h1 id="page-title" className="top_ranked_content__title">
                    Top Ranked Stocks
                </h1> */}
                {error && <p className="error-message">{error}</p>}
                {isLoading ? (
                    <div>
                        <h3 className="yearly-scores-title">
                            <LoadingDotText text={`Loading data`} />
                        </h3>
                    </div>
                ) : (
                    <GenericTanStackTable data={stockData} columns={columns} onRowClick={(row) => handleRowClick(row.Ticker)}/>
                )}
            </div>
        </PageLayout>
    );
};