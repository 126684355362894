import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getStockDetails, getStockSummary } from '../../../services/stock.service';
import { FinancialSummary, YearlyScore } from 'src/models/stock-types';
import { StockDetail } from './StockDetail';
import { LoadingDotText } from '../../Loading/LoadingDotsText';
import { StockFinancialSummary } from './StockFinancialSummary';

interface StockDetailProps {
    ticker: string;
}

export const StockDetails: React.FC<StockDetailProps> = ({ ticker }) => {
    const [yearlyScores, setYearlyScores] = useState<YearlyScore[]>([]);
    const [financialSummary, setFinancialSummary] = useState<FinancialSummary>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [yearRange, setYearRange] = useState(3);
    const { getAccessTokenSilently } = useAuth0();
    const [isApiUnavailable, setIsApiUnavailable] = useState(false);

       useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            setIsLoading(true);
            setYearlyScores([]);
            setError('');

            const yearlyScoreResponse = await getStockDetails(accessToken, ticker, yearRange);
            const financialSummaryResponse = await getStockSummary(accessToken, ticker);

            if (yearlyScoreResponse.data === undefined) {
                setError('No data available for the given ticker and year range.');
                setIsLoading(false);
                return;
            }

            if(financialSummaryResponse.data === null) {
                setError('No data available for the given ticker and year range.');
                setIsLoading(false);
            }

            if (yearlyScoreResponse.data) {
                setYearlyScores(yearlyScoreResponse.data);
            }

            if (financialSummaryResponse.data) {
                setFinancialSummary(financialSummaryResponse.data);
            }

            if (yearlyScoreResponse.error || financialSummaryResponse.error) {
                setError( 'Error fetching some of the data for the provided ticker and year range.');
            }

            setIsLoading(false);
        };

        fetchData();
    }, [ticker, yearRange]);
    return (
        <div className="auth0-features">
            {isApiUnavailable && (
                <p className="api-status-message">
                    The third-party API "DataJockey.io" is temporarily unavailable. Please
                    try again later.
                </p>
            )}
            <div className="select-container">
                <label className="select-label" htmlFor="yearRange">
                    Years of financial information
                </label>
                <select
                    className="custom-select"
                    value={yearRange}
                    onChange={(e) => setYearRange(Number(e.target.value))}
                >
                    {[1, 2, 3, 4].map((num) => (
                        <option key={num} value={num}>
                            {num}
                        </option>
                    ))}
                </select>
            </div>
            {isLoading ? (
                <div>
                    <h3 className="yearly-scores-title">
                        <LoadingDotText text={`Loading data for ${ticker}`} />
                    </h3>
                </div>
            ) : (
                <div>
                    {error && <p className="error-message">{error}</p>}
                    {financialSummary && (
                        <StockFinancialSummary financialSummary={financialSummary} />
                    )}
                    <div className="auth0-features__grid">
                        {yearlyScores.map((score, index) => {
                            const previousYearScore =
                                index > 0 ? yearlyScores[index - 1] : null;

                            return (
                                <StockDetail
                                    key={score.year}
                                    year={score.year}
                                    yearScore={score}
                                    previousYearScore={previousYearScore}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};