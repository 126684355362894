import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavBarTabProps {
	path: string;
	label: string;
}

export const NavBarTab: React.FC<NavBarTabProps> = ({ path, label }) => {
	return (
		<NavLink
			to={path}
			className={({ isActive }) =>
				isActive ? 'nav-bar__tab mobile-nav-bar__tab--active' : 'nav-bar__tab'
			}
		>
			{label}
		</NavLink>
	);
};
