// src/pages/subscription-page.tsx
import { useEffect, useState } from 'react';
import { SuccessDisplay } from 'src/components/Payment/SuccessDisplay';
import { Message } from 'src/components/Payment/Message';
import { SubscriptionPlan } from 'src/components/Payment/SubscriptionPlan';

export default function SubscriptionPage() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id') ?? '');
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return (
      <div className="subscription-page">
        <h1>Choose Your Plan</h1>
        <div className="subscription-plans">
          <SubscriptionPlan
            planName="Starter Plan"
            price="$20.00"
            lookupKey="Starter_Plan-81fe63e"
            description="Limited access to stock tips."
          />
          <SubscriptionPlan
            planName="Pro Plan"
            price="$40.00"
            lookupKey="Premium_Plan-81fe63e"
            description="Unlimited access to stock tips."
          />
          <SubscriptionPlan
            planName="Premium Plan"
            price="$60.00"
            lookupKey="Premium_Plan-81fe63e"
            description="More details about each stock tip."
          />
        </div>
      </div>
    );
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}