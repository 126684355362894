import axios, { AxiosRequestConfig } from 'axios';
// import {
// 	AiAnalysisApiResponse,
// 	ApiResponse,
// 	StockDetailsApiResponse,
// 	StockFilingsApiResponse,
// 	StockDailyCloseApiResponse,
// } from "../models/api-response";
import {fetchStockFilings } from "./external-api.service";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;



export const createCheckoutSessions = async (
    accessToken: string,
    sessionData: Record<string, string>
): Promise<any> => {
    const params = new URLSearchParams(sessionData);

    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/create-checkout-session`,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${accessToken}`,
        },
        data: params.toString(),
    };

    const response = await axios(config);
    return {
        data: response.data,
        headers: response.headers,
    };
};

export const createPortalSessions = async (
    accessToken: string,
    sessionData: Record<string, string>
): Promise<any> => {
    const params = new URLSearchParams(sessionData);

    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/create-portal-session`,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${accessToken}`,
        },
        data: params.toString(),
    };

    const response = await axios(config);
    return {
        data: response.data,
        headers: response.headers,
    };
};