import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../components/PageLayout';
import { getStockSummary, getTopFilings } from '../services/stock.service';
import LoadingSpinner from 'src/components/Loading/LoadingSpinner';
import { StockFilingCard } from 'src/components/StockFilings/StockFilingCard';
import { useStockContext } from 'src/context/StockContext/StockContext';
import { FinancialSummary } from 'src/models/stock-types';

export const StockFilingsPage: React.FC = () => {
    const { stocks, setStocks } = useStockContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [cardsLoading, setCardsLoading] = useState<number>(0);
    const [financialSummaries, setFinancialSummaries] = useState<Record<string, FinancialSummary>>({});
    const { getAccessTokenSilently } = useAuth0();

    const fetchData = async () => {
        const accessToken = await getAccessTokenSilently();
        setIsLoading(true);
        setStocks([]);

        const { data, error } = await getTopFilings(accessToken);
        if (data) {
            setStocks(data);
            setCardsLoading(data.length);
            await fetchFinancialSummaries(data, accessToken);
            setIsLoading(false);
        }

        if (error) {
            setError('failed to fetch data');
        }
    };

    const fetchFinancialSummaries = async (stocks: any[], accessToken: string) => {
        const summaries: Record<string, FinancialSummary> = {};
        for (const stock of stocks) {
            const financialSummaryResponse = await getStockSummary(accessToken, stock.stockTicker);
            if (financialSummaryResponse.data) {
                summaries[stock.stockTicker] = financialSummaryResponse.data;
            }
        }
        setFinancialSummaries(summaries);
        setCardsLoading(0);
    };

    useEffect(() => {
        if (stocks.length === 0) {
            fetchData();
        }
    }, []);

    return (
        <PageLayout>
            <div className="auth0-features">
                <h2 className="auth0-features__title">Weekly Stocks</h2>
                <div className="select-container">
                    {!isLoading && (
                        <div className="fetch-button-container">
                            <button className="button" onClick={fetchData}>
                                Fetch Stocks
                            </button>
                        </div>
                    )}
                </div>
                <div className="stockFilingCard-wrapper">
                    {isLoading || cardsLoading > 0 ? (
                        <LoadingSpinner />
                    ) : (
                        stocks.map((stock) => (
                            <StockFilingCard
                                key={stock.stockTicker}
                                companyName={stock.companyName}
                                ticker={stock.stockTicker}
                                tradeDate={stock.purchaseFiling.tradeDate}
                                industry={stock.purchaseFiling.industry}
                                numberOfInsiderTraders={stock.purchaseFiling.numberOfInsiderTraders}
                                purchasePrice={stock.purchaseFiling.purchasePrice}
                                ownershipIncrease={stock.purchaseFiling.ownershipIncrease}
                                quantity={stock.purchaseFiling.quantity}
                                href={`/stockDetails/${stock.stockTicker}/financialData`}
                                financialSummary={financialSummaries[stock.stockTicker]}
                            />
                        ))
                    )}
                    {error && <p>Error: {error}</p>}
                </div>
            </div>
        </PageLayout>
    );
};
