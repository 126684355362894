import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { NavBarTab } from './NavBarTab';

export const NavBarTabs: React.FC = () => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="nav-bar__tabs">
			{/* <NavBarTab path="/public" label="Public" /> */}
			{isAuthenticated && (
				<>
					<NavBarTab path="/stockFilings" label="Filings" />
					<NavBarTab path="/stockDetails" label="Stocks" />
					<NavBarTab path="/subscription" label="Subscription" />
					<NavBarTab path="/profile" label="Profile" />
					{/* <NavBarTab path="/stockDetails" label="Details" /> */}
				</>
			)}
		</div>
	);
};
