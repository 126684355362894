import { Link } from 'react-router-dom';
import { FinancialSummary } from 'src/models/stock-types';

interface StockFilingCardProps {
    companyName: string;
    ticker: string;
    tradeDate: string;
    industry: string;
    numberOfInsiderTraders: number;
    purchasePrice: number;
    ownershipIncrease: number;
    quantity: number;
    href: string;
    financialSummary?: FinancialSummary;
}

export const StockFilingCard: React.FC<StockFilingCardProps> = ({
    companyName,
    ticker,
    tradeDate,
    industry,
    numberOfInsiderTraders,
    purchasePrice,
    ownershipIncrease,
    quantity,
    href,
    financialSummary,
}) => {
    const decodeHtmlEntities = (str: string) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    };
    const decodedTitle = decodeHtmlEntities(companyName);

    const getClassForValue = (value: number, max: number = 100) => {
        const percentage = (value / max) * 100;
        if (percentage >= 0 && percentage < 30 || percentage < 0) {
            return 'red-metric';
        } else if (percentage >= 30 && percentage < 60) {
            return 'orange-metric';
        } else if (percentage >= 60 && percentage <= 100) {
            return 'green-metric';
        }
        return '';
    };

    return (
        <div className="stockFilingCard-container">
            <Link to={href} className="stockFilingCard-link">
                <div>
                    <div className="p-6">
                        <h2 className="stockFilingCard-title">{decodedTitle}</h2>
                        <p className="stockFilingCard-header">Ticker: {ticker}</p>
                        <p className="stockFilingCard-header">Purchased: {tradeDate}</p>
                        <p className="stockFilingCard-paragraph">Industry: {industry}</p>
                        <p className="stockFilingCard-paragraph">
                            Number of Insider Traders: {numberOfInsiderTraders}
                        </p>
                        {financialSummary ? (
                            <>
                                <p className="stockFilingCard-paragraph">
                                    Trend Adjusted Score:
                                    <span className={getClassForValue(financialSummary.trendAdjustedScore)}>
                                        {financialSummary.trendAdjustedScore}
                                    </span>
                                </p>
                                <p className="stockFilingCard-paragraph">
                                    Financial Strength Percentage:
                                    <span className={getClassForValue(financialSummary.financialStrengthPercentage)}>
                                        {financialSummary.financialStrengthPercentage}%
                                    </span>
                                </p>
                                <p className="stockFilingCard-paragraph">
                                    Rating Score:
                                    <span className={getClassForValue(financialSummary.ratingScore, 5)}>
                                        {financialSummary.ratingScore} / 5
                                    </span>
                                </p>
                                <p className="stockFilingCard-paragraph">
                                    Rating Recommendation:
                                    <span className={getClassForValue(financialSummary.ratingScore, 5)}>
                                        {financialSummary.ratingRecommendation}
                                    </span>
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="stockFilingCard-paragraph">
                                    Purchase Price: ${purchasePrice.toFixed(2)}
                                </p>
                                <p className="stockFilingCard-paragraph">
                                    Ownership Increase: {ownershipIncrease}%
                                </p>
                                <p className="stockFilingCard-paragraph">Quantity: {quantity}</p>
                            </>
                        )}
						{/* <p className="stockFilingCard-paragraph">
							Purchase Price: ${purchasePrice.toFixed(2)}
						</p>
						<p className="stockFilingCard-paragraph">
							Ownership Increase: {ownershipIncrease}%
						</p>
						<p className="stockFilingCard-paragraph">Quantity: {quantity}</p> */}
						Read more &rarr;
					</div>
				</div>
			</Link>
		</div>
	);
};
