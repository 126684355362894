import React from 'react';
import { NavLink } from 'react-router-dom';

interface MobileNavBarTabProps {
	path: string;
	label: string;
	handleClick: () => void;
}

export const MobileNavBarTab: React.FC<MobileNavBarTabProps> = ({
	path,
	label,
	handleClick,
}) => {
	return (
		<NavLink
			to={path}
			onClick={handleClick}
			className={({ isActive }) =>
				isActive
					? 'mobile-nav-bar__tab mobile-nav-bar__tab--active'
					: 'mobile-nav-bar__tab'
			}
		>
			{label}
		</NavLink>
	);
};
