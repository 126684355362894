import React from 'react';
import { FinancialSummary } from 'src/models/stock-types';

interface StockFinancialSummaryProps {
    financialSummary: FinancialSummary;
}

export const getClassForValue = (value: number, max: number = 100) => {
    const percentage = (value / max) * 100;
    if (percentage >= 0 && percentage < 30 || percentage < 0) {
        return 'red-metric spacing';
    } else if (percentage >= 30 && percentage < 60) {
        return 'orange-metric spacing';
    } else if (percentage >= 60 && percentage <= 100) {
        return 'green-metric spacing';
    }
    return '';
};

export const StockFinancialSummary: React.FC<StockFinancialSummaryProps> = ({
    financialSummary,
}) => {
    return (
        <div className="financial-summary-container">
            <ul>
                {/* <li className="financial-summary__description">
                    <span className="financial-summary__label">Latest Financial Score:</span>
                    <span className={getClassForValue(financialSummary.latestFinancialScore)}>
                        {financialSummary.latestFinancialScore}
                    </span>
                </li> */}
                <li className="financial-summary__description">
                    <span className="financial-summary__label">Trend Adjusted Score:</span>
                    <span className={getClassForValue(financialSummary.trendAdjustedScore)}>
                        {financialSummary.trendAdjustedScore}
                    </span>
                </li>
                <li className="financial-summary__description">
                    <span className="financial-summary__label">Financial Strength Percentage:</span>
                    <span className={getClassForValue(financialSummary.financialStrengthPercentage)}>
                        {financialSummary.financialStrengthPercentage}%
                    </span>
                </li>
                <li className="financial-summary__description">
                    <span className="financial-summary__label">Rating:</span>
                    <span className={getClassForValue(financialSummary.ratingScore, 5)}>{financialSummary.rating}</span>
                </li>
                <li className="financial-summary__description">
                    <span className="financial-summary__label">Rating Score:</span>
                    <span className={getClassForValue(financialSummary.ratingScore, 5)}>
                        {financialSummary.ratingScore} / 5
                    </span>
                </li>
                <li className="financial-summary__description">
                    <span className="financial-summary__label">Rating Recommendation:</span>
                    <span className={getClassForValue(financialSummary.ratingScore, 5)}>
                        {financialSummary.ratingRecommendation}
                    </span>
                </li>
            </ul>
        </div>
    );
};