import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getStockDailyClosePrice } from '../../../services/stock.service';
import { StockDailyClosePrice } from 'src/models/stock-types';
import LoadingDotText from 'src/components/Loading/LoadingDotsText';

interface StockPageHeaderProps {
	ticker: string;
}

const StockPageHeader: React.FC<StockPageHeaderProps> = ({ ticker }) => {
	const [dailyClosePrice, setDailyClosePrice] =
		useState<StockDailyClosePrice | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				setError(null);
				const accessToken = await getAccessTokenSilently();
				const { data, error } = await getStockDailyClosePrice(
					accessToken,
					ticker
				);

				if (error) {
					setError(error.message);
				} else {
					setDailyClosePrice(data);
				}
			} catch (err) {
				setError('An unexpected error occurred');
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [getAccessTokenSilently, ticker]);

	const formattedPrice = dailyClosePrice
		? new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
		  }).format(Number(dailyClosePrice.closePrice))
		: '';

	const formattedDate = dailyClosePrice
		? new Intl.DateTimeFormat('en-US', {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
		  }).format(new Date(dailyClosePrice.date))
		: '';

	return (
		<div className="stock-header">
			<p className="stock-ticker">{ticker}</p>
			{isLoading && <LoadingDotText text="" />}
			{error && <p className="error-message">X</p>}
			{/* {!isLoading && !error && dailyClosePrice && (
				<p className="stock-price">
					{formattedPrice} - <span className="stock-date">{formattedDate}</span>
				</p>
			)} */}
		</div>
	);
};

export default StockPageHeader;
